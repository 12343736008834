<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-3xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<p class="text-left text-gray-700">
								Getting started is pretty simple and can be done in a few minutes. Please use this webpage for answers to our most frequently asked questions.
							</p>
							<div class="grid grid-cols-2 grid-flow-col w-2/1">
								<div class="space-y-2 row-span-2 col-span-2">
									<p class="text-lg text-left text-gray-700 tracking-wide font-semibold">
										<span class="text-green-600">FREE TRIAL</span> | <span class="text-green-600">SUBSCRIPTIONS </span> |
										<span class="text-green-600">RENEWALS</span>
									</p>
									<ul class="space-y-1 ml-3 text-gray-700">
										<li>
											1. FULL access <span class="font-semibold">FREE TRIAL</span> (no commitment, no cancellation required)
											<router-link :to="{ name: 'Free10DayTrial' }" class="text-blue-400 font-semibold">10 day free trial (Click here)</router-link>
										</li>
										<li>
											2.
											<router-link :to="{ name: 'HowDoISubscribe' }" rel="noreferrer" class="text-blue-400 font-semibold"
												>Purchase a subscription (Click here)</router-link
											>
										</li>
										<li>
											3.
											<router-link :to="{ name: 'HowDoIRenewMySubscription' }" class="text-blue-400 font-semibold"
												>Renew your subscription (Click here)</router-link
											>
										</li>
									</ul>
								</div>
							</div>
							<hr />
							<div class="space-y-2">
								<p>
									Please use a <span class="font-semibold">Computer</span> with <span class="font-semibold">Chrome</span> Browser or
									<span class="font-semibold">Firefox</span> Browser to Subscribe or Renew to avoid errors.
								</p>
								<p class="font-semibold">After you receive your user login information you can use our service.</p>
							</div>
							<hr />
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/check-sign.png" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="space-y-1 text-left ml-3 text-gray-700">
										<li>
											We <span class="font-semibold">ONLY</span> accept
											<span v-if="!isPizza"><span class="font-semibold">VISA</span> and <span class="font-semibold">MASTERCARD credit</span> cards and </span>
											BITCOIN
										</li>
										<li>
											<div class="font-semibold">(No gift cards, No Prepaid credit cards)</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/cros.png" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 text-gray-700">
										<li>
											<div class="font-semibold">ONLY PAY ON OUR OFFICIAL WEBSITE. BEWARE OF SCAMMERS!</div>
										</li>
										<li>
											<div>We do <span class="font-semibold">NOT</span> accept <span class="font-semibold">Paypal or Zelle payments.</span></div>
										</li>
										<li>
											<div>We do <span class="font-semibold">NOT</span> auto charge. Payments do NOT auto renew.</div>
										</li>
										<li>
											<div>We do <span class="font-semibold">NOT</span> provide Adult channels. We are a family service.</div>
										</li>
										<li>
											<div>We do <span class="font-semibold">NOT</span> provide support for recording or catchup.</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="space-y-1">
								<h5 class="font-semibold text-lg">PRICING</h5>
								<p>Pricing as of <span class="font-semibold">February 1 2021</span>:</p>
								<p>10 Day trial = FREE (no commitment, no cancellation required)</p>
								<ul>
									<li>1 month = 24.99 USD</li>
									<li>3 months = 51.99 USD</li>
									<li>6 months = 89.99 USD</li>
									<li>12 months = 159.99 USD</li>
								</ul>
								<p class="font-semibold">NOTE: All prices are in USD</p>
							</div>
							<div>
								<h5 class="font-semibold text-lg">RECOMMENDED HARDWARE:</h5>
								<div class="flex">
									<img src="../../../assets/images/mini-icon/android-icon.png" style="width: 20%" alt="android" />
									<div class="flex items-center inline-block">
										<p>We recommend using an <span class="font-semibold">Android TV Box</span> for the best experience with our service.</p>
									</div>
								</div>
								<div>
									<p class="mb-1">Click here to view our list of recommended devices.</p>
									<router-link :to="{ name: 'WhatHardwareIsRecommended' }" class="font-semibold text-blue-500"
										>What hardware is recommended? (Click here)</router-link
									>
								</div>
							</div>
							<div class="space-y-2 w-2/3">
								<h5 class="font-semibold text-lg">INSTALLATION GUIDES:</h5>
								<p>Choose your device from the list below and follow our step-by-step installation guides based on what software/app you want to use.</p>
								<p class="font-semibold">NOTE: If your device is not found in the list below then we do NOT provide any support for it.</p>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/remote.jpg" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 text-gray-700">
										<li><span class="font-semibold text-lg">Fire Products:</span> FireTV Firestick Firestick4K Fire Cube</li>
										<li>
											<router-link :to="{ name: 'fireAmazonFireTvFireStickFireTv4K' }" class="text-blue-700">Click Here</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/android-tv.jpg" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 space-y-1 text-gray-700">
										<li>
											<span class="font-semibold text-lg">Android TV:</span>
										</li>
										<li><p>Nvidia-Shield/Xiomi-MiBox/Sony android tv</p></li>
										<li>
											<router-link :to="{ name: 'androidTvNvidiaShieldXiomiMiBoxSonyAndroidTv' }" class="text-blue-700">Click Here</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/android-mobile.png" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 space-y-1 text-gray-700">
										<li>
											<span class="font-semibold text-lg">Android Mobile:</span>
										</li>
										<li><p>Samsung/LG/Sony/Google/Xiaomi/Nokia</p></li>
										<li><p>Huawei/OnePlus/Motorola/HTC</p></li>
										<li>
											<router-link :to="{ name: 'WhatDevicesCanIWatchOnSupportedDevices' }" class="text-blue-700">Click Here</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/android-box.png" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 space-y-1 text-gray-700">
										<li>
											<span class="font-semibold text-lg">Android TV box/stick:</span>
										</li>
										<li><p>Any generic Android box</p></li>
										<li>
											<router-link :to="{ name: 'androidMobileDevices' }" class="text-blue-700">Click Here</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/ios-devices.png" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 space-y-1 text-gray-700">
										<li>
											<span class="font-semibold text-lg">iOS devices:</span>
										</li>
										<li><p>iPhone/iPad/AppleTV</p></li>
										<li>
											<router-link :to="{ name: 'iOsDevicesIPhoneIPadAppleTvOs13' }" class="text-blue-700">Click Here</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/laptop.jpg" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 space-y-1 text-gray-700">
										<li>
											<span class="font-semibold text-lg">PC:</span>
										</li>
										<li><p>Laptop/PC/Mac book laptop/Web-interface</p></li>
										<li>
											<router-link :to="{ name: 'pcLaptopPcMacbookWebInterface' }" class="text-blue-700">Click Here</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="space-y-2">
								<h5 class="font-semibold">UNSUPPORTED DEVICES:</h5>
								<p>Sorry we do <span class="font-semibold">NOT</span> support the following devices/apps/media</p>
							</div>
							<div class="flex w-2/1">
								<img src="../../../assets/images/mini-icon/cros-sign-black.png" class="row-span-1 col-span-1 w-24" alt="" />
								<div class="row-span-2 flex justify-center items-center col-span-3">
									<ul class="text-left ml-3 space-y-1 text-gray-700">
										<li>
											<span class="font-semibold">ROKU: NOT supported</span>
										</li>
									</ul>
								</div>
							</div>
							<div class="font-semibold mt-3" style="margin-top: 15px">
								<p>Xtream code: NOT supported</p>
								<p>Mag Box: NOT supported</p>
								<p>MAC address: NOT supported</p>
								<p>Portals: NOT supported</p>
								<p>Samsung TV: NOT supported</p>
								<p>LGTV: NOT supported</p>
							</div>
							<div class="space-y-1" v-if="!isPizza">
								<p class="font-semibold text-lg">SPEEDTEST</p>
								<p><span class="font-semibold">Speedtest Link</span> (ios devices, android mobile, computer)</p>
								<a :href="$store.state.brandInfo.speedTestLink" class="hover:text-blue-700">{{ $store.state.brandInfo.speedTestLink }}</a>
							</div>
							<hr />
							<div class="space-y-1">
								<p><span class="font-semibold text-lg">How to run speedtest</span> ( {{ $store.state.brandInfo.appName }} app)</p>
								<a :href="`/general/whatInternetBandwidthSpeedDoINeed`" class="hover:text-blue-700">How to run Speedtest</a>
							</div>
							<div class="space-y-2">
								<p class="font-semibold text-lg">IMPORTANT YOU MAY REQUIRE A VPN TO USE OUR SERVICE</p>
								<p>
									Please use a VPN as a resolution to any buffering/freezing that you may experience. Internet service providers are throttling and slowing down
									connections to our servers which leads to buffering and freezing issues you’re experiencing. This isn’t something that can be fixed from our end
									and can happen at anytime even if it worked perfectly for you without a VPN in the past. You can rest assured that it is our mission to provide
									you with the best service available and we continuously keep our servers in tip top shape to ensure all our customers receive only the best
								</p>
								<p class="font-semibold">Commonly Asked Questions:</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: What is a VPN?</p>
								<p>
									A: A VPN disguises your internet network from your internet provider in order to stop them from seeing what you use your internet for and how
									you choose to use your internet.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: I called my internet provider and they told me that they do not throttle my internet or slow down my speeds?</p>
								<p>
									A: we can assure you that after providing support for many customers we can determine that internet providers do not like to advertise or admit
									to their customers that they throttle their speeds. After connecting a VPN the issue almost magically disappears which clearly displays that the
									internet provider is throttling the speed.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q:But my HULU, Amazon, Netflix accounts work just fine without buffering?</p>
								<p>
									A: This is specific to our servers and a VPN is the only solution to stop your internet provider from slowing down your speeds when using our
									service.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: What VPN’s do you recommend?</p>
								<p>A: Our Recommended VPN providers are:</p>
								<div class="font-semibold">
									<p>-Ipvanish</p>
									<p><a href="https://surfshark.com/">Surfshark </a></p>
									<p>-Nord VPN</p>
								</div>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: How can I install a VPN?</p>
								<p>A: Please visit our recommended VPN provider’s website for detailed installation instructions guides for all of your devices.</p>
								<p>Additional how-to installation videos are available on YouTube.</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: But I already have a VPN, why do I need to switch?</p>
								<p>
									A: Not all VPN providers are created equal. Please choose a VPN from our recommended VPN providers listed above. These VPN's have been
									thoroughly tested for best results.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: I’m already using a VPN and still experiencing issues, why?</p>
								<p>
									A: If you are already using one of our recommended VPN providers and still experiencing buffering, please try changing the server location
									within the VPN.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: Should I choose the fastest server on my VPN?</p>
								<p>A: Choosing the fastest server option on your VPN will not always provide the best results.</p>
								<p>Please try multiple locations until you're able to find a server location that provides you a buffer-free experience.</p>
								<p>You may need to cycle throughout all of the VPN server locations to find the best one for you.</p>
								<p>Please visit your VPN provider's website for more information on how to change the server location.</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: Which VPN server locations do you recommend?</p>
								<p>
									A: VPN services provide us multiple server locations options to choose from. The best server location for you will vary. The VPN server location
									that worked perfectly for you today may not necessarily work the best for you tomorrow due to congestion. This is one of the reasons that VPN
									services provide several locations. Switching locations only takes a few short seconds. Before you begin, we recommend clearing cache or
									rebooting your device for best results.
								</p>
								<p>Some of our suggested VPN server locations are:</p>
								<ul class="py-3">
									<li>-USA</li>
									<li>-Canada</li>
									<li>-Denmark</li>
									<li>-Sweden</li>
									<li>-Switzerland</li>
									<li>-Norway</li>
									<li>-Latvia</li>
									<li>-Finland</li>
								</ul>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: Sometimes my service works better without my VPN, why?</p>
								<p>
									A: Yes it's important to note that Internet providers may only throttle at certain times or during popular events. VPN's are only required when
									you're <span class="font-semibold">actively</span> being throttled. There may be situations where you will need to disconnect your VPN for best
									results. We realize this can be confusing but these are tested and proven methods so please try these troubleshooting tips before contacting us
									for any additional support.
								</p>
							</div>
							<hr />
							<p>Your <span class="font-semibold text-lg">username</span> is the <span class="font-semibold">email</span> address you used to sign up with.</p>
							<div class="space-y-2">
								<p><span class="font-semibold text-lg">RESEND PASSWORD</span></p>
								<p>
									If you forgot your password or you just want to double check that you are using the correct password you can
									<span class="font-semibold">resend</span> your password to your account email address at the following link
								</p>
								<a :href="`${$store.state.brandInfo.siteLink}/Portal/`" class="text-blue-700">{{ $store.state.brandInfo.siteLink }}/Portal/</a>
								<p>Password will take approximately <span class="font-semibold">5 minutes</span> to send to your email.</p>
							</div>
							<div>
								<p class="font-semibold text-lg">CHANGE PASSWORD</p>
								<p>You can also reset / change your password at the following link.</p>
								<ul>
									<li>
										1. Go to your myaccount
										<a :href="`${$store.state.brandInfo.siteLink}/Portal/`" class="text-blue-700">{{ $store.state.brandInfo.siteLink }}/Portal/</a>
									</li>
									<li>2. login using your username and password and click <span class="font-semibold">LOGIN</span> button</li>
									<li>3. Click on <span class="font-semibold">Account details</span> tab</li>
									<li>
										4. Enter your current password. Enter your new password and then confirm your password and click
										<span class="font-semibold">SAVE CHANGES</span> button.
									</li>
								</ul>
							</div>
							<div class="space-y-1 text-pink-700">
								<p>
									*<span class="font-semibold">Important</span> Do <span class="font-semibold">NOT</span> use any special characters
									<span class="font-semibold">!@#$%^&*()./~`'-=</span> etc
								</p>
								<p class="font-semibold">ONLY use Letters (A-Z) and Numbers (0-9)</p>
							</div>
							<hr />
							<div class="text-center py-10">
								<router-link :to="{ name: 'Home' }" class="py-4 px-8 text-lg border-2 border-green-500 rounded-full hover:bg-green-500 hover:text-white"
									>Help Center</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'FirstSteps',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	computed: {
		...mapState({
			isPizza: (state) => state.brandInfo.name === 'pizzatime'
		})
	}
};
</script>

<style scoped></style>
